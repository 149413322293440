import moment, { Moment } from "moment";
import { DocumentReference, QueryDocumentSnapshot } from "firebase/firestore";
import { ProjectType, StatusMockup } from "../enums";

export default class Mockup implements IMockup {
  public id!: string;
  public ref?: DocumentReference;
  public status!: StatusMockup;
  public type!: ProjectType;
  public model?: string;
  public prompt!: Prompt;
  public promptStrength?: number;
  public imageUrl?: string;
  public mockupUrl?: string;
  public upscaledUrl?: string;
  public dateCreated!: Moment;
  public dateUpdated!: Moment;
  constructor(param: IMockup) {
    Object.assign(this, param);
  }
  public static fromFirestore(snapshot: QueryDocumentSnapshot): Mockup {
    return new Mockup({
      id: snapshot.id,
      ref: snapshot.ref,
      status: snapshot.get("status"),
      type: snapshot.get("type"),
      model: snapshot.get("model") ?? undefined,
      prompt: snapshot.get("prompt"),
      promptStrength: snapshot.get("promptStrength"),
      imageUrl: snapshot.get("imageUrl") ?? undefined,
      mockupUrl: snapshot.get("mockupUrl") ?? undefined,
      upscaledUrl: snapshot.get("upscaledUrl") ?? undefined,
      dateCreated: moment(snapshot.get("dateCreated").toDate()),
      dateUpdated: moment(snapshot.get("dateUpdated").toDate()),
    });
  }
}

interface IMockup {
  id: string;
  ref?: DocumentReference;
  status: StatusMockup;
  type: ProjectType;
  model?: string;
  prompt: Prompt;
  promptStrength?: number;
  imageUrl?: string;
  mockupUrl?: string;
  upscaledUrl?: string;
  dateCreated: Moment;
  dateUpdated: Moment;
}

interface Prompt {
  room: string;
  style: string;
  optional?: string;
}
