import { Image } from "image-js";
import { UPLOAD_RESOLUTION } from "./constants";

export const translate: any = {
  bedroom: "quarto",
  kitchen: "cozinha",
  bathroom: "banheiro",
  "living room": "sala de estar",
  "dining room": "sala de jantar",
  "home office": "escritório",
  industrial: "industrial",
  modern: "moderno",
  minimalist: "minimalista",
  contemporary: "contemporâneo",
  vintage: "vintage",
  rustic: "rústico",
  tropical: "tropical",
};

// https://github.com/replicate/controlnet/blob/main/annotator/util.py
export async function processImage(file: Blob) {
  let image = await Image.load(await file.arrayBuffer());

  let height = image.height;
  let width = image.width;
  let k = UPLOAD_RESOLUTION / Math.min(height, width);

  height *= k;
  width *= k;

  const newHeight = Math.round(height / 64) * 64;
  const newWidth = Math.round(width / 64) * 64;

  image = image.resize({
    width: newWidth,
    height: newHeight,
    preserveAspectRatio: false,
  });

  console.log(image.width, image.height);

  return {
    width: image.width,
    height: image.height,
    blob: await image.toBlob(),
  };
}
