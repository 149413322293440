import BaseDb from "./baseDb";
import toFirestore from "./toFirestore";
import User from "../models/user";

import { FirestoreDataConverter } from "firebase/firestore";

import { omit } from "lodash";

export default class UserDb extends BaseDb<User, { userId: string }> {
  _path: string = "users/userId";
  getConverter(): FirestoreDataConverter<User> {
    return {
      fromFirestore: (snapshot, _options) =>
        new User({
          id: snapshot.id,
          email: snapshot.get("email"),
          credits: snapshot.get("credits"),
        }),
      toFirestore: (user) => toFirestore(omit(user, ["id"])),
    };
  }
}
