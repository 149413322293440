import { useCallback, useContext, useEffect, useState } from "react";
import {
  styled,
  Container,
  Typography,
  Grid,
  alpha,
  ImageList,
  useMediaQuery,
} from "@mui/material";
import { getDoc, getDocs, query } from "firebase/firestore";
import ProjectDb from "../../db/projectDb";

import Project from "../../models/project";
import { useParams } from "react-router";
import Mockup from "../../models/mockup";
import MockupDb from "../../db/mockupDb";
import { map, orderBy } from "lodash";

import MockupItem from "../mockup/item";
import MockupViewer from "../mockup/viewer/mockupViewer";
import RedesignerHeader from "../redesigner/header";
import VirtualStagerHeader from "../virtual-stager/header";
import { ProjectType, StatusMockup } from "../../enums";
import { UserContext } from "../../contexts/userContext";
import theme from "../../theme";

const PREFIX = "viewProject";

const classes = {
  container: `${PREFIX}-container`,
  breadcrumbs: `${PREFIX}-breadcrumbs`,
  mockupItem: `${PREFIX}-mockupItem`,
  image: `${PREFIX}-image`,
  overlay: `${PREFIX}-overlay`,
  status: `${PREFIX}-status`,
  actionIcon: `${PREFIX}-actionIcon`,
  list: `${PREFIX}-list`,
  tip: `${PREFIX}-tip`,
  help: `${PREFIX}-help`,
};

const StyledContainer = styled(Container)(({ theme }) => ({
  [`&.${classes.container}`]: {
    padding: theme.spacing(4),
  },
  [`& .${classes.breadcrumbs}`]: {
    fontWeight: 700,
    padding: theme.spacing(0, 0, 4, 0),
  },
  [`& .${classes.mockupItem}`]: {
    width: "auto",
    padding: theme.spacing(0),
    marginRight: theme.spacing(4),
  },
  [`& .${classes.image}`]: {
    objectFit: "contain",
    maxWidth: theme.spacing(64),
    maxHeight: theme.spacing(64),
    borderRadius: theme.shape.borderRadius,
  },
  [`& .${classes.overlay}`]: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: theme.shape.borderRadius,
    background: alpha(theme.palette.primary.main, 0.8),
  },
  [`& .${classes.status}`]: {
    textTransform: "capitalize",
    color: theme.palette.text.secondary,
  },
  [`& .${classes.actionIcon}`]: {
    cursor: "pointer",
    color: theme.palette.text.secondary,
  },
  [`& .${classes.list}`]: {
    overflow: "inherit",
    padding: theme.spacing(0, 0),
  },
  [`& .${classes.tip}`]: {
    fontStyle: "italic",
    padding: theme.spacing(0, 0, 2, 0),
    color: theme.palette.secondary.main,
  },
  [`& .${classes.help}`]: {
    fontStyle: "italic",
    padding: theme.spacing(2, 0, 0, 0),
    color: theme.palette.secondary.main,
  },
}));

export default function Projector() {
  const { id } = useParams();
  const user = useContext(UserContext);
  const [project, setProject] = useState<Project>();
  const [mockups, setMockups] = useState<Mockup[]>();

  const [currentMockup, setCurrentMockup] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const [selectedMockup, setSelectedMockup] = useState<Mockup>();

  useEffect(() => {
    if (user && id)
      getDoc(
        new ProjectDb().doc({
          userId: user.id,
          projectId: id,
        })
      ).then((doc) => setProject(doc.data()));
  });

  useEffect(() => {
    if (user && id)
      getDocs(
        query(
          new MockupDb().collection({
            userId: user.id,
            projectId: id,
            mockupId: "",
          })
        )
      ).then((snapshot) => {
        const mockupsSnapshot = orderBy(
          map(snapshot.docs, (doc) => doc.data()),
          (mockup) => mockup.dateCreated,
          "desc"
        );
        setMockups(mockupsSnapshot);
      });
  });

  const openMockupViewer = useCallback((index: number) => {
    setCurrentMockup(index);
    setIsViewerOpen(true);
  }, []);

  const selectMockup = (index: number) => {
    if (mockups) setSelectedMockup(mockups[index]);
  };

  const closeMockupViewer = () => {
    setCurrentMockup(0);
    setIsViewerOpen(false);
  };

  const desktop = useMediaQuery(theme.breakpoints.up("md"));

  return project && mockups ? (
    <StyledContainer className={classes.container}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h5" className={classes.breadcrumbs}>
            {`Projeto / ${project.id}`}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            Pode levar alguns minutos para cada geração.
          </Typography>
        </Grid>
      </Grid>
      {project.type === ProjectType.REDESIGN ? (
        <RedesignerHeader project={project} selectedMockup={selectedMockup} />
      ) : (
        <VirtualStagerHeader
          project={project}
          selectedMockup={selectedMockup}
        />
      )}
      <Grid container>
        <Grid item xs={12}>
          {mockups.filter((mockup) => mockup.status === StatusMockup.PROCESSING)
            .length > 0 ? (
            <Typography variant="body1" className={classes.tip}>
              Assim que seu mockup for gerado, poderá visualizá-lo, usá-lo para
              gerar outra ideia ou aumentar sua resolução.
            </Typography>
          ) : (
            <></>
          )}
          <ImageList cols={desktop ? 3 : 1} gap={16} className={classes.list}>
            {mockups.map((mockup, index) => (
              <MockupItem
                key={mockup.id}
                mockup={mockup}
                mockupIndex={index}
                onMockupClick={openMockupViewer}
                onMockupSelect={selectMockup}
              ></MockupItem>
            ))}
          </ImageList>
        </Grid>
      </Grid>
      {isViewerOpen && (
        <MockupViewer
          mockups={mockups}
          currentIndex={currentMockup}
          closeOnClickOutside={true}
          onClose={closeMockupViewer}
        ></MockupViewer>
      )}
    </StyledContainer>
  ) : (
    <></>
  );
}
