import React from "react";
import {
  styled,
  Container,
  Typography,
  Grid,
  Box,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import Logo from "../utils/logo";
import ReactCompareImage from "react-compare-image";

import ImageB from "../../assets/b.png";
import ImageA from "../../assets/a.png";
import { IconBrandGoogle } from "@tabler/icons";
import { GoogleAuthProvider } from "firebase/auth";
import { signInWithProvider } from "../../contexts/userContext";

const PREFIX = "home";

const classes = {
  container: `${PREFIX}-container`,
  slogan: `${PREFIX}-slogan`,
  action: `${PREFIX}-action`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.container}`]: {
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.background.default,
  },
  [`& .${classes.slogan}`]: {
    textAlign: "center",
    padding: theme.spacing(4, 0, 4, 0),
    color: theme.palette.text.primary,
  },
  [`& .${classes.action}`]: {
    margin: theme.spacing(4, 0),
  },
}));

export default function Home() {
  const navigate = useNavigate();
  const theme = useTheme();
  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    await signInWithProvider(provider).then(() => navigate("/app/recents"));
  };

  return (
    <StyledBox className={classes.container}>
      <Container fixed>
        <Grid container>
          <Grid item xs={12}>
            <Logo padding={0} />
            <Typography variant="body1" className={classes.slogan}>
              Explore interiores vazios usando I.A. em um estalar de dedos.
            </Typography>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Box
                sx={{
                  width: useMediaQuery(theme.breakpoints.up("sm"))
                    ? "50%"
                    : "100%",
                }}
              >
                <ReactCompareImage
                  aspectRatio="wider"
                  sliderPositionPercentage={0.35}
                  leftImage={ImageA}
                  leftImageCss={{ objectFit: "contain", borderRadius: "16px" }}
                  rightImage={ImageB}
                  rightImageCss={{ objectFit: "contain", borderRadius: "16px" }}
                />
                <Button
                  fullWidth
                  size="large"
                  variant="contained"
                  className={classes.action}
                  onClick={signInWithGoogle}
                  startIcon={<IconBrandGoogle size={20} strokeWidth={3} />}
                >
                  Entrar com o Google
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </StyledBox>
  );
}
