import React, { useCallback } from "react";
import { styled, Drawer, List, ListItem, ListItemText } from "@mui/material";

import { map } from "lodash";

import { useNavigate, useLocation } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";
import Logo from "./logo";

const PREFIX = "sideBar";

const classes = {
  drawer: `${PREFIX}-drawer`,
  drawerPaper: `${PREFIX}-drawerPaper`,
  drawerItem: `${PREFIX}-drawerItem`,
  drawerItemText: `${PREFIX}-drawerItemText`,
};

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  [`&.${classes.drawer}`]: {
    zIndex: 0,
    flexShrink: 0,
    width: theme.spacing(24),
    backgroundColor: theme.palette.background.paper,
  },
  [`& .${classes.drawerPaper}`]: {
    width: theme.spacing(24),
    backgroundColor: theme.palette.background.paper,
  },
  [`& .${classes.drawerItem}`]: {
    "&.MuiButtonBase-root.Mui-selected": {
      backgroundColor: "transparent",
      textDecorationLine: "underLine",
    },
  },
  [`& .${classes.drawerItemText}`]: {
    "&:hover > .MuiListItemText-root": {
      cursor: "pointer",
      textDecorationLine: "underLine",
    },
  },
}));

export default function SideBar() {
  const navigate = useNavigate();
  const location = useLocation();

  const handleOnClick = useCallback(
    (path: string) => navigate(`/app${path}`),
    [navigate]
  );

  return (
    <StyledDrawer
      variant="permanent"
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Logo />
      <List>
        {map(RoutesMenu, (route) => (
          <ListItem
            button
            key={route.name}
            className={classes.drawerItem}
            selected={location.pathname === `/app${route.path}`}
          >
            <ListItemText
              primary={route.name}
              className={classes.drawerItemText}
              onClick={() => handleOnClick(route.path)}
            />
          </ListItem>
        ))}
        <ListItem button key={"Logout"} className={classes.drawerItem}>
          <ListItemText
            primary={"Logout"}
            className={classes.drawerItemText}
            onClick={() => {
              signOut(getAuth());
              navigate(`/`);
            }}
          />
        </ListItem>
      </List>
    </StyledDrawer>
  );
}

type RouteMenu = {
  name: string;
  path: string;
};

export const RoutesMenu: RouteMenu[] = [
  {
    name: "Projetos",
    path: "/recents",
  },
  /*
  {
    name: "Redesigner",
    path: "/redesigner",
  },
  */
  {
    name: "Virtual Stager",
    path: "/virtual-stager",
  },
];
