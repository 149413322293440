import {
  Grid,
  Box,
  Button,
  SelectChangeEvent,
  styled,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  useMediaQuery,
  useTheme,
  TextField,
} from "@mui/material";
import { addDoc, getDocs, query, updateDoc, where } from "firebase/firestore";
import moment from "moment";
import { useState, useEffect, useContext, useRef } from "react";
import useImage from "use-image";
import MockupDb from "../../db/mockupDb";
import { ProjectType, RoomStyle, RoomType, StatusMockup } from "../../enums";
import Model from "../../models/model";
import Mockup from "../../models/mockup";
import Project from "../../models/project";
import { UserContext } from "../../contexts/userContext";
import { first, map, orderBy } from "lodash";
import ModelDb from "../../db/modelDb";
import PreferenceSelect from "../utils/preferenceSelect";
import UserDb from "../../db/userDb";

import React from "react";
import Konva from "konva";
import { Layer, Line, Stage, Image } from "react-konva";
import { HERO_IMAGE_MAX_WIDTH } from "../../constants";

const PREFIX = "redesignerViewer";

const classes = {
  gridContent: `${PREFIX}-gridContent`,
  image: `${PREFIX}-image`,
  canvas: `${PREFIX}-canvas`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.gridContent}`]: {
    padding: theme.spacing(4, 0),
  },
  [`& .${classes.canvas}`]: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
}));

type Props = {
  project: Project;
  selectedMockup: Mockup | undefined;
};

export default function VirtualStagerHeader({
  project,
  selectedMockup,
}: Props) {
  const theme = useTheme();
  const user = useContext(UserContext);

  const [room, setRoom] = useState<RoomType>(RoomType.BEDROOM);
  const [style, setStyle] = useState<RoomStyle>(RoomStyle.MODERN);
  const [optional, setOptional] = useState<string>("");

  const [models, setModels] = useState<Model[]>();
  const [filteredModels, setFilteredModels] = useState<Model[]>();

  const [model, setModel] = useState<Model>();

  const [open, setOpen] = useState<boolean>(false);

  const [selectedImage, setSelectedImage] = useState<string>(
    project.originalUrl
  );

  const [imgProject] = useImage(project.originalUrl);
  const [imgMockup] = useImage(selectedImage);

  const [tool, setTool] = useState<string>("pen");
  const [lines, setLines] = useState<any[]>([]);
  const isDrawing = useRef<boolean>(false);

  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    if (selectedMockup) {
      setSelectedImage(selectedMockup.mockupUrl!);
    }
  }, [selectedMockup]);

  useEffect(() => {
    if (room)
      getDocs(
        query(
          new ModelDb().collection({
            modelId: "",
          }),
          where("room", "in", [room, "all"]),
          where("public", "==", true)
        )
      ).then((snapshot) => {
        const modelsSnapshot = orderBy(
          map(snapshot.docs, (doc) => doc.data()),
          (model) => model.name,
          "asc"
        );
        setModels(modelsSnapshot);
      });
  }, [room]);

  useEffect(() => {
    if (models && models.length > 0) {
      setFilteredModels(models.filter((model) => model.name === "interioria"));
    }
  }, [models]);

  useEffect(() => {
    if (filteredModels && filteredModels.length > 0)
      setModel(first(filteredModels)!);
  }, [filteredModels]);

  const handleChange = (event: SelectChangeEvent) => {
    const value = event.target.value;
    const name = event.target.name;

    switch (name) {
      case "room":
        setRoom(value as RoomType);
        break;
      case "style":
        setStyle(value as RoomStyle);
        break;
      case "model":
        setModel(models!.find((model) => model.name === value));
        break;
      default:
        break;
    }
  };

  const handleTyping = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOptional(event.target.value);
  };

  const handleClick = () => {
    if (user && project && model) {
      if (user.credits > 0) {
        addDoc(
          new MockupDb().collection({
            userId: user.id,
            projectId: project.id,
            mockupId: "",
          }),
          new Mockup({
            id: "",
            status: StatusMockup.QUEUED,
            type: ProjectType.VIRTUAL_STAGING,
            model: model.name,
            prompt: {
              room: room,
              style: style,
              optional: optional,
            },
            promptStrength: 0.65,
            imageUrl: project.originalUrl,
            dateCreated: moment(),
            dateUpdated: moment(),
          })
        ).then(() => {
          updateDoc(
            new UserDb().doc({
              userId: user.id,
            }),
            {
              credits: user.credits - 1,
            }
          );
        });
      }
      setOpen(true);
    }
  };

  const handleEdit = () => {};

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const openWhats = () => {
    window.open(
      "https://api.whatsapp.com/send/?phone=5531998064653&text=Ol%C3%A1%2C+estou+interessado+em+comprar+mais+cr%C3%A9ditos+para+usar+no+interioria%21&type=phone_number&app_absent=0"
    );
  };

  const action = (
    <Button color="secondary" onClick={openWhats}>
      COMPRAR +
    </Button>
  );

  const desktop = useMediaQuery(theme.breakpoints.up("md"));

  const handleMouseDown = (e: Konva.KonvaEventObject<MouseEvent>) => {
    isDrawing.current = true;
    const pos = e.target.getStage()!.getPointerPosition();
    setLines([...lines, { tool, points: [pos!.x, pos!.y] }]);
  };

  const handleMouseMove = (e: Konva.KonvaEventObject<MouseEvent>) => {
    if (!isDrawing.current) {
      return;
    }
    const stage = e.target.getStage();
    const point = stage!.getPointerPosition();
    let lastLine = lines[lines.length - 1];

    lastLine.points = lastLine.points.concat([point!.x, point!.y]);

    lines.splice(lines.length - 1, 1, lastLine);
    setLines(lines.concat());
  };

  const handleMouseUp = () => {
    isDrawing.current = false;
  };

  useEffect(() => {
    if (imgProject) {
      let width = imgProject.width;
      let height = imgProject.height;

      if (desktop) {
        if (width > HERO_IMAGE_MAX_WIDTH) {
          width = HERO_IMAGE_MAX_WIDTH;
          height = (width * imgProject.height) / imgProject.width;
        }
      } else {
        width = window.innerWidth - 80;
        height = (width * imgProject.height) / imgProject.width;
      }

      setDimensions({
        width,
        height,
      });
    }
  }, [imgProject]);

  return user && model && filteredModels && imgProject ? (
    <StyledGrid container className={classes.gridContent}>
      <Grid item xs={12}>
        <Box display="flex" flexDirection={desktop ? "row" : "column"}>
          <Box>
            <Stage
              width={dimensions.width}
              height={dimensions.height}
              onMouseDown={handleMouseDown}
              onMousemove={handleMouseMove}
              onMouseup={handleMouseUp}
            >
              <Layer>
                {lines.map((line, i) => (
                  <Line
                    key={i}
                    points={line.points}
                    stroke="black"
                    strokeWidth={10}
                    tension={0.5}
                    lineCap="round"
                    lineJoin="round"
                    globalCompositeOperation={"source-over"}
                  />
                ))}
                <Image
                  image={imgProject}
                  globalCompositeOperation="source-in"
                  cornerRadius={theme.shape.borderRadius}
                  width={dimensions.width}
                  height={dimensions.height}
                />
                <Image
                  image={imgMockup}
                  globalCompositeOperation="destination-over"
                  cornerRadius={theme.shape.borderRadius}
                  width={dimensions.width}
                  height={dimensions.height}
                />
              </Layer>
            </Stage>
          </Box>
          {desktop ? <Box paddingX={2} /> : <Box paddingY={2} />}
          {lines.length === 0 && (
            <Box
              display="flex"
              height="inherit"
              flexDirection="column"
              justifyContent="flex-end"
              flexGrow={1}
            >
              <PreferenceSelect
                preference={RoomType}
                value={room}
                label="ESPAÇO"
                name="room"
                handleChange={handleChange}
              />
              <Box paddingY={2} />
              <FormControl variant="standard">
                <InputLabel>MODELO</InputLabel>
                <Select
                  name="model"
                  label="MODELO"
                  value={model.name}
                  onChange={handleChange}
                  disabled
                >
                  {filteredModels.map((value) => (
                    <MenuItem key={value.name} value={value.name}>
                      {value.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Box paddingY={2} />
              <PreferenceSelect
                preference={RoomStyle}
                value={style}
                label="ESTILO"
                name="style"
                handleChange={handleChange}
                disabled={model.name !== "interioria"}
              />
              <Box paddingY={2} />
              <TextField
                id="standard-multiline-static"
                label="DETALHES (OPCIONAL)"
                multiline
                rows={4}
                value={optional}
                variant="standard"
                placeholder="with lots of plants"
                onChange={handleTyping}
                InputLabelProps={{ shrink: true }}
              />
              <Box paddingY={2} />
              <Button
                size="large"
                variant="contained"
                onClick={handleClick}
                disabled={!project.maskUrl}
              >
                Gerar nova ideia
              </Button>
              {desktop ? <Box paddingY={1} /> : <Box paddingY={0} />}
            </Box>
          )}
          {lines.length > 0 && (
            <Box
              display="flex"
              height="inherit"
              flexDirection="column"
              justifyContent="flex-end"
              flexGrow={1}
            >
              <Button size="large" variant="contained" onClick={handleEdit}>
                Salvar edição
              </Button>
              {desktop ? <Box paddingY={1} /> : <Box paddingY={0} />}
            </Box>
          )}
        </Box>
      </Grid>
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        action={user.credits < 1 ? action : <></>}
        onClose={handleClose}
        autoHideDuration={3000}
        message={`Créditos: ${user.credits}`}
      />
    </StyledGrid>
  ) : (
    <></>
  );
}
