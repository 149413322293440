import React, { useState } from "react";
import { Box, IconButton, Menu, MenuItem, styled } from "@mui/material";
import { IconMenu } from "@tabler/icons";
import { useNavigate } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";

const PREFIX = "popMenu";

const classes = {
  menu: `${PREFIX}-menu`,
};

const StyledDiv = styled("div")(({ theme }) => ({
  [`& .${classes.menu}`]: {
    padding: theme.spacing(2),
  },
}));

export default function PopMenu() {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <StyledDiv>
      <Box className={classes.menu}>
        <IconButton
          edge="end"
          onClick={handleClick}
          color={"primary"}
          size="large"
        >
          <IconMenu size={32} />
        </IconButton>
      </Box>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {" "}
        <MenuItem
          onClick={() => {
            navigate(`/app/recents`);
            handleClose();
          }}
        >
          Projetos
        </MenuItem>
        {/* <MenuItem
          onClick={() => {
            navigate(`/app/redesigner`);
            handleClose();
          }}
        >
          Redesigner
        </MenuItem> */}
        <MenuItem
          onClick={() => {
            navigate(`/app/virtual-stager`);
            handleClose();
          }}
        >
          Virtual Stager
        </MenuItem>
        <MenuItem
          onClick={() => {
            signOut(getAuth());
            navigate(`/`);
          }}
        >
          Logout
        </MenuItem>
      </Menu>
    </StyledDiv>
  );
}
