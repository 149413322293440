import React, { useState, useEffect, PropsWithChildren } from "react";

import {
  AuthProvider,
  getAdditionalUserInfo,
  getAuth,
  onAuthStateChanged,
  signInWithPopup,
} from "firebase/auth";
import { User as AuthUser } from "firebase/auth";
import { onSnapshot, setDoc } from "firebase/firestore";
import UserDb from "../db/userDb";
import User from "../models/user";

export const UserContext = React.createContext<User | undefined>(undefined);

export const UserProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [user, setUser] = useState<User | undefined>();
  const [uid, setUid] = useState<string>();

  useEffect(() => {
    return onAuthStateChanged(getAuth(), async (userAuth) => {
      if (userAuth) {
        setUid(userAuth.uid);
      } else {
        setUid(undefined);
      }
    });
  }, []);

  useEffect(() => {
    if (uid) {
      return onSnapshot(new UserDb().doc({ userId: uid }), (snapshot) => {
        setUser(snapshot.data());
      });
    } else {
      setUser(undefined);
    }
  }, [uid]);

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};

export function signInWithProvider(provider: AuthProvider): Promise<void> {
  return signInWithPopup(getAuth(), provider)
    .then((authResult) => {
      if (getAdditionalUserInfo(authResult) && authResult.user) {
        if (getAdditionalUserInfo(authResult)?.isNewUser) {
          signUp(authResult.user);
        }
      }
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
}

function signUp(user: AuthUser): Promise<void> {
  return setDoc(
    new UserDb().doc({
      userId: user.uid,
    }),
    new User({
      id: "",
      email: user.email || "",
      credits: 10,
    })
  );
}
