import BaseDb from "./baseDb";
import toFirestore from "./toFirestore";
import Model from "../models/model";

import { FirestoreDataConverter } from "firebase/firestore";

import { omit } from "lodash";

export default class ModelDb extends BaseDb<Model, { modelId: string }> {
  _path: string = "models/modelId";
  getConverter(): FirestoreDataConverter<Model> {
    return {
      fromFirestore: (snapshot, _options) =>
        new Model({
          id: snapshot.id,
          name: snapshot.get("name"),
          room: snapshot.get("room"),
          public: snapshot.get("public"),
        }),
      toFirestore: (project) => toFirestore(omit(project, ["id"])),
    };
  }
}
