import BaseDb from "./baseDb";
import toFirestore from "./toFirestore";
import Project from "../models/project";

import { FirestoreDataConverter } from "firebase/firestore";

import moment from "moment";
import { omit } from "lodash";

export default class ProjectDb extends BaseDb<
  Project,
  { userId: string; projectId: string }
> {
  _path: string = "users/userId/projects/projectId";
  getConverter(): FirestoreDataConverter<Project> {
    return {
      fromFirestore: (snapshot, _options) =>
        new Project({
          id: snapshot.id,
          name: snapshot.get("name"),
          type: snapshot.get("type"),
          originalUrl: snapshot.get("originalUrl"),
          maskUrl: snapshot.get("maskUrl"),
          dateCreated: moment(snapshot.get("dateCreated").toDate()),
          dateUpdated: moment(snapshot.get("dateUpdated").toDate()),
          width: snapshot.get("width"),
          height: snapshot.get("height"),
          segmentation: snapshot.get("segmentation"),
        }),
      toFirestore: (project) => toFirestore(omit(project, ["id"])),
    };
  }
}
