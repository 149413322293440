import BaseDb from "./baseDb";
import toFirestore from "./toFirestore";

import { FirestoreDataConverter } from "firebase/firestore";

import moment from "moment";
import { omit } from "lodash";
import Mockup from "../models/mockup";

export default class MockupDb extends BaseDb<
  Mockup,
  { userId: string; projectId: string; mockupId: string }
> {
  _path: string = "users/userId/projects/projectId/mockups/mockupId";
  getConverter(): FirestoreDataConverter<Mockup> {
    return {
      fromFirestore: (snapshot, _options) =>
        new Mockup({
          id: snapshot.id,
          ref: snapshot.ref,
          status: snapshot.get("status"),
          type: snapshot.get("type"),
          prompt: snapshot.get("prompt"),
          imageUrl: snapshot.get("imageUrl"),
          mockupUrl: snapshot.get("mockupUrl"),
          upscaledUrl: snapshot.get("upscaledUrl"),
          dateCreated: moment(snapshot.get("dateCreated").toDate()),
          dateUpdated: moment(snapshot.get("dateUpdated").toDate()),
        }),
      toFirestore: (mockup) => toFirestore(omit(mockup, ["id"])),
    };
  }
}
