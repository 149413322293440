import { QueryDocumentSnapshot } from "firebase/firestore";

export default class User implements IUser {
  public id!: string;
  public email!: string;
  public credits!: number;
  constructor(param: IUser) {
    Object.assign(this, param);
  }
  public static fromFirestore(snapshot: QueryDocumentSnapshot): IUser {
    return new User({
      id: snapshot.id,
      email: snapshot.get("email"),
      credits: snapshot.get("credits"),
    });
  }
}

interface IUser {
  id: string;
  email: string;
  credits: number;
}
