import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import {
  RoomColor,
  RoomMaterial,
  RoomStyle,
  RoomType,
  Studiomk27,
} from "../../enums";
import { translate } from "../../utils";

type Props = {
  preference:
    | typeof RoomStyle
    | typeof RoomType
    | typeof RoomColor
    | typeof RoomMaterial
    | typeof Studiomk27;
  value: RoomType | RoomStyle | RoomColor | RoomMaterial | Studiomk27;
  label: string;
  name: string;
  handleChange: (event: SelectChangeEvent) => void;
  disabled?: boolean;
};

export default function PreferenceSelect({
  preference,
  value,
  label,
  name,
  handleChange,
  disabled = false,
}: Props) {
  return value ? (
    <FormControl variant="standard">
      <InputLabel>{label}</InputLabel>
      <Select
        value={value}
        onChange={handleChange}
        name={name}
        label={label}
        disabled={disabled}
      >
        {Object.values(preference).map((value) => (
          <MenuItem key={value} value={value}>
            {translate[value] || value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  ) : (
    <></>
  );
}
