import { CSSProperties, useCallback, useEffect, useState } from "react";
import styles from "./mockupViewer.module.css";
import Mockup from "../../../models/mockup";
import { StatusMockup } from "../../../enums";

type Props = {
  mockups: Mockup[];
  currentIndex?: number;
  backgroundStyle?: CSSProperties;
  closeOnClickOutside?: boolean;
  onClose?: () => void;
  closeComponent?: JSX.Element;
};

export default function MockupViewer(props: Props) {
  const [currentIndex, setCurrentIndex] = useState(props.currentIndex ?? 0);

  const changeImage = (delta: number) => {
    let nextIndex = (currentIndex + delta) % props.mockups.length;
    if (nextIndex < 0) nextIndex = props.mockups.length - 1;
    setCurrentIndex(nextIndex);
  };

  const handleClick = useCallback(
    (event: any) => {
      if (!event.target || !props.closeOnClickOutside) {
        return;
      }

      const checkId = event.target.id === "MockupViewer";
      const checkClass = event.target.classList.contains(
        "react-simple-image-viewer__slide"
      );

      if (checkId || checkClass) {
        event.stopPropagation();
        props.onClose?.();
      }
    },
    [props.onClose]
  );

  const handleKeyDown = useCallback(
    (event: any) => {
      if (event.key === "Escape") {
        props.onClose?.();
      }

      if (["ArrowLeft", "h"].includes(event.key)) {
        changeImage(-1);
      }

      if (["ArrowRight", "l"].includes(event.key)) {
        changeImage(1);
      }
    },
    [props.onClose, changeImage]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <div
      id="MockupViewer"
      className={`${styles.wrapper} react-simple-image-viewer__modal`}
      onKeyDown={handleKeyDown}
      onClick={handleClick}
      style={props.backgroundStyle}
    >
      <div
        className={`${styles.content} react-simple-image-viewer__modal-content`}
        onClick={handleClick}
      >
        <div className={`${styles.slide} react-simple-image-viewer__slide`}>
          <img
            className={`${styles.image}`}
            src={
              props.mockups[currentIndex].status === StatusMockup.UPSCALED
                ? props.mockups[currentIndex].upscaledUrl!
                : props.mockups[currentIndex].mockupUrl!
            }
          ></img>
        </div>
      </div>
    </div>
  );
}
