import React, { useCallback, useContext, useState } from "react";
import { useDropzone } from "react-dropzone";
import {
  styled,
  Container,
  Typography,
  Grid,
  Box,
  useMediaQuery,
} from "@mui/material";
import { IconUpload } from "@tabler/icons";
import { forEach } from "lodash";
import { doc, setDoc } from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import ProjectDb from "../../db/projectDb";
import moment from "moment";
import Project from "../../models/project";
import { useNavigate } from "react-router-dom";
import { ProjectType } from "../../enums";
import { UserContext } from "../../contexts/userContext";
import theme from "../../theme";
import { processImage } from "../../utils";

const PREFIX = "virtual-stager";

const classes = {
  container: `${PREFIX}-container`,
  breadcrumbs: `${PREFIX}-breadcrumbs`,
  gridContent: `${PREFIX}-gridContent`,
  upload: `${PREFIX}-upload`,
  uploadIcon: `${PREFIX}-uploadIcon`,
  help: `${PREFIX}-help`,
};

const StyledContainer = styled(Container)(({ theme }) => ({
  [`&.${classes.container}`]: {
    padding: theme.spacing(4),
  },
  [`& .${classes.breadcrumbs}`]: {
    fontWeight: 700,
    padding: theme.spacing(0, 0, 4, 0),
  },
  [`& .${classes.gridContent}`]: {
    padding: theme.spacing(4, 0),
  },
  [`& .${classes.upload}`]: {
    width: "100%",
    backgroundColor: "transparent",
    borderRadius: theme.shape.borderRadius,
    borderColor: theme.palette.secondary.main,
    borderWidth: 2,
    borderStyle: "dashed",
  },
  [`& .${classes.uploadIcon}`]: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    color: theme.palette.secondary.main,
  },
  [`& .${classes.help}`]: {
    fontStyle: "italic",
    padding: theme.spacing(2, 0, 0, 0),
    color: theme.palette.secondary.main,
  },
}));

export default function VirtualStager() {
  const navigate = useNavigate();
  const user = useContext(UserContext);
  const [uploading, setUploading] = useState<string | undefined>(undefined);

  const onDropAccepted = useCallback(
    (files: any) => {
      if (user) {
        forEach(files, async (file) => {
          setUploading("Carregando...");

          const projectId = doc(
            new ProjectDb().collection({
              userId: user.id,
              projectId: "",
            })
          ).id;

          const originalRef = ref(
            getStorage(),
            `/users/${user.id}/projects/${projectId}/original.png`
          );

          const processed = await processImage(file);

          uploadBytes(originalRef, processed.blob, {
            customMetadata: { name: file.name },
          })
            .then(() => getDownloadURL(originalRef))
            .then((originalUrl) => {
              setDoc(
                new ProjectDb().doc({
                  userId: user!.id,
                  projectId: projectId,
                }),
                new Project({
                  id: projectId,
                  name: "Untitled",
                  type: ProjectType.VIRTUAL_STAGING,
                  originalUrl: originalUrl,
                  dateCreated: moment(),
                  dateUpdated: moment(),
                  width: processed.width,
                  height: processed.height,
                })
              );
            })
            .then(() => {
              setUploading("Segmentando o interior, aguarde...");
              setTimeout(() => {
                navigate(`/app/project/${projectId}`);
                setUploading(undefined);
              }, 75000);
            });
        });
      }
    },
    [user, navigate]
  );

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpeg", ".jpg"],
    },
    onDropAccepted: onDropAccepted,
  });

  return (
    <StyledContainer className={classes.container}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h5" className={classes.breadcrumbs}>
            Virtual Stager
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            {uploading
              ? uploading
              : "Clique para carregar uma foto de um interior vazio para explorar ideias de decoração virtual."}
          </Typography>
          <Typography variant="body1" className={classes.help}>
            Sugerimos fotos com um ângulo de 90 graus de frente para uma parede.
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={4} className={classes.gridContent}>
        <Grid item md={6} xs={12}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            className={classes.upload}
            sx={{
              height: useMediaQuery(theme.breakpoints.up("md"))
                ? theme.spacing(40)
                : theme.spacing(32),
            }}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            <IconUpload className={classes.uploadIcon} />
          </Box>
        </Grid>
      </Grid>
    </StyledContainer>
  );
}
