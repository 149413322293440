import { createTheme } from "@mui/material";

const theme = createTheme({
  components: {
    MuiButton: {
      defaultProps: { disableElevation: true },
      styleOverrides: {
        contained: { textTransform: "none" },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        colorPrimary: {
          color: "#8C8C8C",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#8C8C8C",
        },
      },
    },
  },
  palette: {
    primary: { main: "#000000" },
    secondary: { main: "#8C8C8C" },
    background: {
      default: "#F5F5F5",
      paper: "#FFFFFF",
    },
    text: {
      primary: "#000000",
      secondary: "#FFFFFF",
    },
  },
  shape: {
    borderRadius: 16,
  },
  typography: {
    fontFamily: "Ubuntu Mono",
  },
});

export default theme;
