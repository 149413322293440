import { initializeApp } from "firebase/app";
import { initializeFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBXL62YVg1J-woxnl2fXT7ALNvj1MdP4Sc",
  authDomain: "interiores-ai.firebaseapp.com",
  databaseURL: `https://interiores-ai.firebaseio.com`,
  projectId: "interiores-ai",
  storageBucket: "interiores-ai.appspot.com",
  messagingSenderId: "952030631337",
  appId: "1:952030631337:web:19f76dbeed3061f71660b8",
};

const app = initializeApp(firebaseConfig);
export const db = initializeFirestore(app, { ignoreUndefinedProperties: true });

export default app;
