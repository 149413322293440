import moment, { Moment } from "moment";
import { QueryDocumentSnapshot } from "firebase/firestore";
import { ProjectType } from "../enums";

export default class Project implements IProject {
  public id!: string;
  public name!: string;
  public type!: ProjectType;
  public originalUrl!: string;
  public maskUrl?: string;
  public dateCreated!: Moment;
  public dateUpdated!: Moment;
  public width?: number;
  public height?: number;
  public segmentation?: any[];
  constructor(param: IProject) {
    Object.assign(this, param);
  }
  public static fromFirestore(snapshot: QueryDocumentSnapshot): Project {
    return new Project({
      id: snapshot.id,
      name: snapshot.get("name"),
      type: snapshot.get("type"),
      originalUrl: snapshot.get("originalUrl"),
      maskUrl: snapshot.get("maskUrl"),
      dateCreated: moment(snapshot.get("dateCreated").toDate()),
      dateUpdated: moment(snapshot.get("dateUpdated").toDate()),
      width: snapshot.get("width"),
      height: snapshot.get("height"),
      segmentation: snapshot.get("segmentation"),
    });
  }
}

interface IProject {
  id: string;
  name: string;
  type: string;
  originalUrl: string;
  maskUrl?: string;
  dateCreated: Moment;
  dateUpdated: Moment;
  width?: number;
  height?: number;
  segmentation?: any[];
}
