import { QueryDocumentSnapshot } from "firebase/firestore";

export default class Model implements IModel {
  public id!: string;
  public name!: string;
  public room!: string;
  public public!: boolean;
  constructor(param: IModel) {
    Object.assign(this, param);
  }
  public static fromFirestore(snapshot: QueryDocumentSnapshot): Model {
    return new Model({
      id: snapshot.id,
      name: snapshot.get("name"),
      room: snapshot.get("room"),
      public: snapshot.get("public"),
    });
  }
}

interface IModel {
  id: string;
  name: string;
  room: string;
  public: boolean;
}
