export enum ProjectType {
  REDESIGN = "redesign",
  EXPLORATION = "exploration",
  VIRTUAL_STAGING = "virtual staging",
}

export enum StatusMockup {
  QUEUED = "queued",
  PROCESSING = "processing",
  FAILED = "failed",
  DONE = "done",
  UPSCALING = "upscaling",
  UPSCALED = "upscaled",
}

export enum RoomType {
  BEDROOM = "bedroom",
  KITCHEN = "kitchen",
  BATHROOM = "bathroom",
  LIVING_ROOM = "living room",
  DINING_ROOM = "dining room",
  HOME_OFFICE = "home office",
}

export enum RoomStyle {
  INDUSTRIAL = "industrial",
  MODERN = "modern",
  MINIMALIST = "minimalist",
  CONTEMPORARY = "contemporary",
  VINTAGE = "vintage",
  RUSTIC = "rustic",
  TROPICAL = "tropical",
}

export enum RoomColor {
  NEUTRAL = "neutral",
  ANALOGOUS = "analogous",
  MONOCHROMATIC = "monochromatic",
  PASTEL = "pastel",
  VIBRANT = "vibrant",
}

export enum RoomMaterial {
  WOOD = "wood",
  METAL = "metal",
  CONCRETE = "concrete",
  GLASS = "glass",
  STONE = "stone",
  PLYWOOD = "plywood",
  GRANITE = "granite",
  MARBLE = "marble",
}

export enum Studiomk27 {
  BEDROOM = "bedroom",
  LIVING_ROOM = "living room",
}
