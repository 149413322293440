import { alpha, Box, ImageListItem, styled, Typography } from "@mui/material";
import { IconSearch, IconTrash, IconWand } from "@tabler/icons";
import { useState } from "react";
import Mockup from "../../models/mockup";
import { StatusMockup } from "../../enums";
import { deleteDoc, updateDoc } from "firebase/firestore";

const PREFIX = "mockupItem";

const classes = {
  imageListItem: `${PREFIX}-imageListItem`,
  mockupItem: `${PREFIX}-mockupItem`,
  actionIcon: `${PREFIX}-actionIcon`,
  image: `${PREFIX}-image`,
  overlay: `${PREFIX}-overlay`,
  imageHolder: `${PREFIX}-imageHolder`,
  status: `${PREFIX}-status`,
};

const StyledImageListItem = styled(ImageListItem)(({ theme }) => ({
  [`&.${classes.imageListItem}`]: {
    minHeight: theme.spacing(32),
  },
  [`& .${classes.actionIcon}`]: {
    cursor: "pointer",
    color: theme.palette.text.secondary,
  },
  [`& .${classes.image}`]: {
    width: "100%",
    borderRadius: theme.shape.borderRadius,
  },
  [`& .${classes.overlay}`]: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: theme.shape.borderRadius,
    background: alpha(theme.palette.primary.main, 0.8),
  },
  [`& .${classes.imageHolder}`]: {
    borderRadius: theme.shape.borderRadius,
    background: alpha(theme.palette.primary.main, 0.8),
  },
  [`& .${classes.status}`]: {
    color: theme.palette.text.secondary,
  },
}));

type Props = {
  mockup: Mockup;
  mockupIndex: number;
  onMockupClick: (index: number) => void;
  onMockupSelect: (index: number) => void;
};

export default function MockupItem({
  mockup,
  mockupIndex,
  onMockupClick,
  onMockupSelect,
}: Props) {
  const [showActions, setShowActions] = useState(false);

  const onMockupUpscale = (mockup: Mockup) => {
    if (mockup) {
      updateDoc(mockup.ref!, { status: StatusMockup.UPSCALING });
    }
  };

  const onMockupDelete = (mockup: Mockup) => {
    if (mockup) {
      deleteDoc(mockup.ref!);
    }
  };

  return (
    <StyledImageListItem key={mockup.id} className={classes.imageListItem}>
      {mockup.status !== StatusMockup.DONE &&
      mockup.status !== StatusMockup.UPSCALED ? (
        <Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            className={classes.overlay}
          >
            <Typography variant="body1" className={classes.status}>
              {mockup.status + "..."}
            </Typography>
          </Box>
          <Box className={classes.imageHolder}></Box>
        </Box>
      ) : (
        <Box
          onMouseEnter={() => setShowActions(true)}
          onMouseLeave={() => setShowActions(false)}
        >
          <Box className={showActions ? classes.overlay : ""}>
            {showActions && (
              <Box
                height="100%"
                display="flex"
                alignItems="center"
                justifyContent="space-around"
              >
                <IconSearch
                  size={20}
                  className={classes.actionIcon}
                  onClick={() => onMockupClick(mockupIndex)}
                />
                {mockup.status !== StatusMockup.UPSCALED && (
                  <IconWand
                    size={20}
                    className={classes.actionIcon}
                    onClick={() => onMockupUpscale(mockup)}
                  />
                )}
                <IconTrash
                  size={20}
                  className={classes.actionIcon}
                  onClick={() => onMockupDelete(mockup)}
                />
              </Box>
            )}
          </Box>
          <img
            src={mockup.mockupUrl}
            className={classes.image}
            loading="lazy"
          />
        </Box>
      )}
    </StyledImageListItem>
  );
}
